import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { HttpErrorResponse } from '@angular/common/http';
import { ManagerService } from 'src/app/services/manager.service';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit, OnDestroy {
  list: boolean; order: any; lists: any[]; orderlist: any[]; id: any;
  // tslint:disable-next-line:variable-name
  constructor(public _nav: NavComponent, private _api: ManagerService) { }

  ngOnInit() {
    this.formReset();
    this.getAllOrder();
    // this.id = setInterval(() => { this.getAllOrder(); }, 30000);
  }

  ngOnDestroy() {
    if (this.id) {
      clearInterval(this.id);
    }
  }

  getAllOrder() {
    this._api.getOrders().subscribe((data: any) => {
      this.lists = data; console.log(data);
    }, (err: HttpErrorResponse) => {
      this._nav.showMsg('error', 'Purchase List Fail', 'Unable to get Purchase List'); });
  }

  getOrder(dat) {
    // this.order = dat;
    this._api.getOrderbyCode(dat.orderno).subscribe((data: any) => {
      this.order = data; console.log(data);
    }, (err: HttpErrorResponse) => {
      this._nav.showMsg('error', 'Purchase List Fail', 'Unable to get Purchase List'); });
  }

  cancelOrder(id) {
    this._api.cancelOrder(id).subscribe((data: any) => {
      this.order = data;
    }, (err: HttpErrorResponse) => {
      this._nav.showMsg('error', 'Purchase Cancel Fail', 'Unable to Cancel Purchase'); });
  }

  acceptOrder(id) {
    this._api.acceptOrder(id).subscribe((data: any) => {
      this.order = data; this.getAllOrder();
    }, (err: HttpErrorResponse) => {
      this._nav.showMsg('error', 'Purchase confirmation Fail', 'Unable to Confirm Purchase'); });
  }

  readyOrder(id) {
    this._api.readyOrder(id).subscribe((data: any) => {
      this.order = data; this.getAllOrder();
    }, (err: HttpErrorResponse) => { alert(err.statusText); });
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.order = {
        orderid: '', orderno: '', fullname: '', mobile: '', total: 0, status: 'p', quantity: 0,
        date: new Date(), item: { name: '', code: '', description: '', discount: 0}};
    }
  }

}
