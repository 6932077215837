import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ManagerService } from 'src/app/services/manager.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { SetupService } from 'src/app/services/setup.service';
import { config } from 'src/environments/environment';

@Component({
  selector: 'app-create-invoice',
  templateUrl: './create-invoice.component.html',
  styleUrls: ['./create-invoice.component.sass']
})
export class CreateInvoiceComponent implements OnInit {
  @ViewChild('invoiceForm') public any: NgForm; public files: any[];
  edit: boolean; item: any; id: any; merchant: any; items: any[] = []; total = 0; p: any;
  image = config.imageUrl + 'Invoice/'; public regex = new RegExp(' ','g');

  // tslint:disable-next-line:max-line-length
  constructor(private api: ManagerService, private set: SetupService, private router: Router, private rout: ActivatedRoute, private use: NavComponent) { }

  ngOnInit() {
    this.formReset();
    this.id = this.rout.snapshot.paramMap.get('id');
    this.getAll(this.id);
    if (this.use.active.merchantid == null) {
      this.getMerchant();
    }
  }

  getAll(id) {
    if (id && id !== 0) {
      this.edit = true;
      this.getInvoice(id);
    }
  }

  async addSlug(event) {
    if(event.target.value != null && !this.item._id && this.item.slug != null) { this.item.slug = event.target.value.toLowerCase().replace(this.regex,'-') }
  }

  getInvoice(id) {
    this.api.get('invoice/'+id).subscribe((data: any) => {
      this.item = data;
      this.items = JSON.parse(this.item.description);
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Invoice Detail');
    });
  }

  getMerchant() {
    this.set.getCompanys().subscribe((data: any) => {
      this.merchant = data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Merchant List');
    });
  }

  fileUpload(event) {
    this.api.Upload(event.target.files[0], 'Invoices').subscribe((data: any) => {
      this.item.image = this.image + data; console.log(this.image, data);
      console.log(this.item.image);
    });
  }

  postInvoice() {
    this.item.description = JSON.stringify(this.items);
    // this.item.items = this.items;
    this.api.post('invoice',this.item).subscribe((data: any) => {
      this.item = data; this.formReset(this.any);
      this.use.showMsg('success', 'Invoice Added', 'New Invoice Added Successfully');
      this.router.navigate(['invoice']);
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Add Fail', 'Unable to Add Invoice Detail');
    });
  }

  putInvoice() {
    this.item.description = JSON.stringify(this.items); 
    // this.item.items = this.items;
    this.api.put('invoice/'+this.item.itemid, this.item).subscribe((data: any) => {
      this.item = data; this.formReset(this.any);
      this.use.showMsg('success', 'Invoice Updated', 'Invoice Details Updated Successfully');
      this.router.navigate(['invoice']);
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Add Fail', 'Unable to Fetch Invoice Detail');
    });
  }

  getTotal() {
    console.log(this.items);
    this.total = 0;
    this.items.forEach((e: any) => {
        this.total += e.amount;
    });
    return this.total.toFixed(2);
  }

  addItem() {
    this.p.amount = this.p.unit_price * this.p.quantity;
    this.items.push(this.p); this.item.amount = this.getTotal();
    this.init();
  }

  remove(val, i) {
    // this.images.slice(i, i+1);
    this.items = this.arrayRemove(this.items, val.name);
    this.item.amount = this.getTotal();
  }

  arrayRemove(arr, val) {
    return arr.filter((ele)=>{
        return ele.name != val;;
    });
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      // const merch = this.use.active.merchantid || '';
      this.item = { image: 'assets/img/acyst-logo.png',
        invoiceid: 0, code: "", name: "", email: "", mobile: "", amount: 0, sms: false,
        description: "", slug: "", status: "Pending", items: [],
        merchantid: this.use.active.merchantid, userid: this.use.active.id, date: new Date()
      };
      this.init();
    }
  }

  init() {
    this.p = {
      name: '', amount: '', unit_price: 0, quantity: 0
    }
  }
}
