import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { SigninService } from 'src/app/services/signin.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-lock',
  templateUrl: './lock.component.html',
  styleUrls: ['./lock.component.css']
})
export class LockComponent implements OnInit {
  log: any; isLoginError: boolean; res: any; returnUrl: string; user: any;
  constructor(private api: SigninService, private router: Router, private route: ActivatedRoute, private _msg: ToastrService) { }

  ngOnInit() {
    this.user = this.api.userDetails();
    this.resetForm();
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  unlock() {
    this.api.loginUser(this.log).subscribe((data: any) => {
      localStorage.setItem('Creed', JSON.stringify(data.access_token));
      localStorage.setItem('Date', JSON.stringify(data.date));
      this._msg.success('Unlocked Successfully', 'UnLocked');
      window.location.href = this.returnUrl;
      // this.router.navigate([this.returnUrl]); location.reload(true);
    }, (err: HttpErrorResponse) => { console.log(err);
      this.isLoginError = true; this.res = 'Incorrect Username or Password';
      this._msg.error('Unable to unlock : ' + err, 'Unlocked');
    });
  }

  resetForm(form?: NgForm) {
    if (form != null) { form.reset(); }
    this.log = { username: this.user.unique_name, password: '' };
  }

}
