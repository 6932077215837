import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { SetupService } from 'src/app/services/setup.service';
import { ExportService } from 'src/app/services/export.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';

@Component({
  selector: 'app-setup-licence',
  templateUrl: './setup-licence.component.html',
  styleUrls: ['./setup-licence.component.css']
})
export class SetupLicenceComponent implements OnInit {
  l: any; com: any[]; branch: any[]; user: any;
  licence: any[] = []; filtered: any[] = [];
  private searhcTitle: string;
  get searchTitle(): string {
    return this.searhcTitle;
  }
  set searchTitle(value: string) {
    this.searhcTitle = value;
    this.filtered = this.searchResult(value);
  }
  // tslint:disable-next-line:variable-name
  constructor(private api: SetupService, private _ext: ExportService, private _use: NavComponent) { }

  ngOnInit() {
    this.user = this._use.active;
    this.formReset();
    this.getAll();
    this.getAllLicence();
  }

  getAll() {
    this.api.getBranchs().subscribe((data: any) => {
      this.branch = data;
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Branch List');
    });
    this.api.getCompanys().subscribe((data: any) => {
      this.com = data;
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Company List');
    });
  }

  getAllLicence() {
    this.api.getLicences().subscribe((data: any) => {
      this.licence = data; this.filtered = data;
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Licence List');
    });
  }

  getCur(id) {
    console.log(id, this.branch);
    const b = this.branch;
    for (let i = 0; i <= b.length; i++) {
      console.log(id, b[i]);
      if (b[i].branchid.toLowerCase() === id.toLowerCase()) {
        console.log(id, b[i].branchid);
        this.l.previous = b[i].expirydate;
      }
    }
  }

  postLicence() {
    this.api.postLicence(this.l).subscribe((data: any) => {
      this.getAllLicence(); this.formReset(); document.getElementById('close').click();
      this._use.showMsg('success', 'Licence Added', 'Added to list Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Adding Fail', 'Unable to Add New Licence');
    });
  }

  putLicence() {
    this.l.muserid = this._use.active.id; this.l.mdate = new Date();
    this.api.putLicence(this.l.licenceid, this.l).subscribe((data: any) => {
      this.getAllLicence(); this.formReset(); document.getElementById('close').click();
      this._use.showMsg('success', 'Licence Updated', 'Details Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
    });
  }

  searchResult(searchString: string) {
    // tslint:disable-next-line:max-line-length
    return this.licence.filter(a => a.branch.name.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.branch.code.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.date.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.previous.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.branch.status.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.branch.company.name.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.next.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.branch.mobile.indexOf(searchString) !== -1);
  }

  formReset() {
    this.l = {
      branchid: '', companyid: '', previous: new Date(), next: new Date(),
      userid: this._use.active.id, date: new Date(), muserid: null, mdate: new Date()
    };
  }
}
