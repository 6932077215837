import { Injectable } from '@angular/core';
// import { AngularFirestore } from '@angular/fire/firestore';
// import { AngularFireDatabase } from '@angular/fire/database';
// import { AngularFireAuth } from '@angular/fire/auth';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  api = environment.apiUrl;
  private isLoading = new Subject<boolean>();
  public Loading = this.isLoading.asObservable();
  activeuser: any;
  userid: any;

  constructor(
    private http: HttpClient, public jwtHelper: JwtHelperService
    ) { }

  show() {
    this.isLoading.next(true);
    // this.Loading = true;
  }

  hide() {
    this.isLoading.next(false);
    // this.Loading = false;
  }

  user() {
    // console.log(this.jwtHelper.getTokenExpirationDate());
    // console.log(this.jwtHelper.tokenGetter());
    return this.jwtHelper.decodeToken(this.jwtHelper.tokenGetter());
  }

  Upload(data: File, type): Observable<any> {
    const fd = new FormData();
    fd.append('file', data);
    return this.http.post(this.api + 'upload/' + type, fd);
  }

  get(route) {
    return this.http.get(this.api + route);
  }

  post(rout, data): Observable<any> {
    return this.http.post(this.api + rout, JSON.stringify(data));
  }

  put(rout, data): Observable<any> {
    return this.http.put(this.api + rout, JSON.stringify(data));
  }

  delete(rout): Observable<any> {
    return this.http.delete(this.api + rout);
  }

}
