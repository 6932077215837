import { Component, OnInit, ViewChild } from '@angular/core';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ManagerService } from 'src/app/services/manager.service';
import { SetupService } from 'src/app/services/setup.service';

@Component({
  selector: 'app-setup-charge',
  templateUrl: './setup-charge.component.html',
  styleUrls: ['./setup-charge.component.sass']
})
export class SetupChargeComponent implements OnInit {
@ViewChild('sessionForm') public any: NgForm;
  ses: any; add: Boolean; edit: Boolean = false; curr: Date;
  charges: any[] = []; merchants: any[] = []; types:any[] = ["SEND", "RECEIVE"]; user: any;
  constructor(private _setup: ManagerService, private api: SetupService, private _use: NavComponent) { }

  ngOnInit() {
    this.user = this._use.active;
    this.curr = new Date();
    this.formReset();
    this.getAllCharge();
    this.getMerchant();
  }

  getAllCharge() {
    this._setup.get('charge').subscribe((data: any) => {
      this.charges = data;
      this._use.showMsg('success', 'Charge List', 'Fetched Charge list Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Charge List Fail', 'Unable to Fetch Charge List');
    });
  }

  getMerchant() {
    this.api.getCompanys().subscribe((data: any) => {
      this.merchants = data;
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Merchant List');
    });
  }
  // getbranch() {
  //   this.api.getCompanys().subscribe((data: any) => {
  //     this.branch = data;
  //     this._use.showMsg('success', 'Charge List', 'Fetched Charge list Successfully');
  //   }, (err: HttpErrorResponse) => {
  //     this._use.showMsg('error', 'Charge List Fail', 'Unable to Fetch Charge List');
  //   });
  // }

  editCharge(data) {
    // this.set = this.charges.find(s => s.chargeid === data);
    this.ses = data;
    this.edit = true;
  }

  delCharge(dat) {
    this._setup.delete('charge/'+dat.chargeid).subscribe((data: any) => {
      this.charges.slice(dat);
    }, (err: HttpErrorResponse) => { 
      this._use.showMsg('error', 'Delete Fail', 'Unable to Delete Charge'); 
    });
  }
  saveCharge() {
    if(this.ses.chargeid > 0) {
      this.putCharge();
    } else {
      this.postCharge();
    }
  }

  postCharge() {
    this._setup.post('charge',this.ses).subscribe((data: any) => {
      this.charges.push(data); this.formReset();
      this._use.showMsg('success', 'Charge Added', 'Added to list Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Adding Fail', 'Unable to Add New Charge');
    });
  }

  putCharge() {
    this.ses.muserid = this._use.active.id; this.ses.mdate = new Date();
    this._setup.put('charge/', this.ses).subscribe((data: any) => {
      this.getAllCharge(); this.formReset();
      this._use.showMsg('success', 'Charge Updated', 'Details Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
    });
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.ses = {
        chargeid: 0, type: "", percentage: true, value: 0, amount: 0, minimum: 0, maximum: 0, charge_on_custmer: false, active: true, merchantid: "", userid: this._use.active.id, date: new Date(), muserid: null, mdate: null
      };
      this.edit = false;
    }
  }

  back() {
    this.formReset();
  }

}
