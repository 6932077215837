import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ExportService } from 'src/app/services/export.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { HttpErrorResponse } from '@angular/common/http';
import { ManagerService } from 'src/app/services/manager.service';
import { SetupService } from 'src/app/services/setup.service';

@Component({
  selector: 'app-packages',
  templateUrl: './packages.component.html',
  styleUrls: ['./packages.component.sass']
})
export class PackagesComponent implements OnInit {
  @ViewChild('packageForm') public any: NgForm; id: number;
  edit: boolean; exp: any; pac: any; res: any; imp: any; packages: any[] = [];
  private _searhcTitle: string; filtered: any[] = [];
  merchant: any;

  get searchTitle(): string {
    return this._searhcTitle;
  }
  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.filtered = this.searchResult(value);
  }
  // tslint:disable-next-line:variable-name
  constructor(private api: ManagerService, private set: SetupService, private _ext: ExportService, private _use: NavComponent) { }

  ngOnInit() {
    this.formReset();
    this.getAllPackage();
    this.getMerchant();
  }

  getAllPackage() {
    this.api.getPackages().subscribe((data: any) => {
      this.packages = data; this.filtered = data;
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Package Details');
    });
  }

  getMerchant() {
    this.set.getCompanys().subscribe((data: any) => {
      this.merchant = data;
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Merchant List');
    });
  }

  editPac(data) {
    this.pac = data;
    this.edit = true; // this.list = true;
  }

  delPac(dat) {
    this.api.delPackage(dat.packagesid).subscribe((data: any) => {
      this.res = data; this.packages.slice(dat);
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Deleting Fail', 'Unable to Delete Package');
    });
  }

  searchResult(searchString: string) {
    // tslint:disable-next-line:max-line-length
    return this.packages.filter(a => a.name.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.code.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.status.indexOf(searchString) !== -1);
  }

  postPackage() {
    this.api.postPackage(this.pac).subscribe((data: any) => {
      this.res = data; this.getAllPackage();
      this.formReset(); document.getElementById('regis').click();
      this._use.showMsg('success', 'Package Added', 'New Package Added Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Adding Fail', 'Unable to Add New Package');
    });
  }

  putPackage() {
    this.pac.muserid = this._use.active.id; this.pac.mdate = new Date();
    this.api.putPackage(this.pac.packagesid, this.pac).subscribe((data: any) => {
      this.res = data; this.getAllPackage(); this.edit = false;
      this.formReset(); document.getElementById('regis').click();
      this._use.showMsg('success', 'Package Updated', 'Details Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
    });
  }

  formReset() {
    this.pac = { packageid: 0, code: '', name: '', type: '',
      discount: 0, active: true, description: '', amount: '', period: 30, merchantid: '',
      userid: this._use.active.id, date: new Date()
    };
    this.edit = false;
  }

}
