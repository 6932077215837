import { Component, OnInit } from '@angular/core';
import { ManagerService } from 'src/app/services/manager.service';
import { SetupService } from 'src/app/services/setup.service';
import { ExportService } from 'src/app/services/export.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { HttpErrorResponse } from '@angular/common/http';

declare var $: any;

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.sass']
})
export class PaymentComponent implements OnInit {
  payment: any[] = []; filtered: any[] = []; total = 0; merchants: any[] = []; rpt: any;
  id: number; exp: any[]; balance = 0; view: boolean = false; tran: any;
  // tslint:disable-next-line:variable-name
  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }
  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.filtered = this.searchResult(value);
    this.getTotal(this.filtered);
  }
  constructor(private api: ManagerService, private set: SetupService, private ext: ExportService, private use: NavComponent) { }

  ngOnInit() {
    $.getScript('assets/js/plugins/footable/footable.all.min.js');
    this.rpt = { merchantid: this.use.active.merchantid, start: '', end: '', settled: false, code: 0 }
    this.getAllPayment();
    this.getMerchants();
  }

  getMerchants() {
    this.set.getCompanys().subscribe((data: any) => {
      this.merchants = data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Merchant List');
    });
  }

  getMerchant(val) {
    this.set.getCompany(val).subscribe((data: any) => {
      this.balance = data.balance;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Merchant Balance');
    });
  }

  getAllPayment() {
    this.api.get('Payment/All').subscribe((data: any) => {
      this.payment = data; this.filtered = data; this.getTotal(data);
      setTimeout(() => {
        $('.footable').footable();
      }, 2000);
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Payment List');
    });
  }
  viewTran(val) {
    this.tran = val;
    this.view = true;
  }

  update() {
    this.api.get('settlement/update/'+ this.rpt.merchantid).subscribe((data: any) => {
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', err.message);
    });
  }

  checkPayment(val) {
    const code = val.transaction_no;
    if(!code || code == 0) { code == val.tranid }
    this.api.get('PayNow/Confirm/'+ code).subscribe((data: any) => {
      this.getAllPayment();
    }, (err: any) => {
      this.use.showMsg('error', err.result.status_message || 'Checking Fail', err.messages || 'Transaction Not Confirmed');
    });
  }

  generate() {
    this.api.post('Payment/Report', this.rpt).subscribe((data: any) => {
      this.payment = data; this.filtered = data; this.getMerchant(this.rpt.merchantid); this.getTotal(data);
      setTimeout(() => {
        $('.footable').footable();
      }, 2000);
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Payment List');
    });
  }

  getTotal(trans) {
    // console.log(trans);
    this.total = 0;
    trans.forEach((e: any) => {
      if (e.code === 1) {
        this.total += e.amount;
      }
    });
    return this.total;
  }

  export() {
    if (this.filtered.length > 0) {
      const data = this.format();
      this.ext.exportAsExcelFile(data, 'items');
      this.use.showMsg('success', 'Transactions Exported', 'Check Downloaded Excel File For Details');
    } else {
      this.use.showMsg('error', 'Transactions Export Fail', 'There is no Transactions Data to Export');
    }
  }

  format() {
    this.id = 1; this.exp = [];
    this.filtered.forEach((e: any) => {
      const ed = {
        SN: this.id, CODE: e.tranid, TYPE: e.type, MOBILE: e.mobile, SERVICE: e.service,
        AMOUNT: e.amount, REFERENCE: e.reference, RESPONSE: e.response, CREATEDDATE: e.date
      };
      this.exp.push(ed); this.id++;
    });
    return this.exp;
  }
  searchResult(searchString: string) {
    // console.log(searchString);
    // tslint:disable-next-line:max-line-length
    return this.payment.filter(a => a.type.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.mobile.indexOf(searchString) !== -1 || a.reference.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.source.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.network.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1);
  }


}
