import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './views/login/login.component';
import { ForgetPasswordComponent } from './views/login/forget-password/forget-password.component';
import { LockComponent } from './views/login/lock/lock.component';
import { NavComponent } from './traits/nav/nav.component';
import { AuthGuard } from './authguard/auth.guard';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { AdminGuard } from './authguard/admin.guard';
import { ExitpageGuard } from './authguard/exitpage.guard';
import { AccountGuard } from './authguard/account.guard';
import { SetupSmsComponent } from './views/setup/setup-sms/setup-sms.component';
import { SetupCompanyComponent } from './views/setup/setup-company/setup-company.component';
import { SupportGuard } from './authguard/support.guard';
import { SetupBranchComponent } from './views/setup/setup-branch/setup-branch.component';
import { BranchGuard } from './authguard/branch.guard';
import { SetupSequenceComponent } from './views/setup/setup-sequence/setup-sequence.component';
import { SetupLicenceComponent } from './views/setup/setup-licence/setup-licence.component';
import { SetupSessionComponent } from './views/setup/setup-session/setup-session.component';
import { SetupSmslogsComponent } from './views/setup/setup-smslogs/setup-smslogs.component';
import { SetupUserComponent } from './views/setup/setup-user/setup-user.component';
import { SetupLocationComponent } from './views/setup/setup-location/setup-location.component';
import { SetupChargeComponent } from './views/setup/setup-charge/setup-charge.component';
import { NotfoundComponent } from './views/error/notfound/notfound.component';

import { CustomersComponent } from './views/manager/customers/customers.component';
import { ViewCustomerComponent } from './views/manager/customers/view-customer/view-customer.component';
import { CreatePaymentComponent } from './views/manager/payments/create-payment/create-payment.component';

import { ItemComponent } from './views/manager/item/item.component';
import { AddItemComponent } from './views/manager/item/add-item/add-item.component';
import { ItemDetailComponent } from './views/manager/item/item-detail/item-detail.component';
import { AlertsComponent } from './views/alerts/alerts.component';
import { SubcribersComponent } from './views/manager/subcribers/subcribers.component';
import { PaymentsComponent } from './views/manager/payments/payments.component';
import { OrdersComponent } from './views/manager/orders/orders.component';
import { PackagesComponent } from './views/manager/packages/packages.component';
import { PurchasesComponent } from './views/manager/purchases/purchases.component';
import { StocksComponent } from './views/manager/item/stocks/stocks.component';
import { UtilityComponent } from './views/manager/utility/utility.component';
import { SettlementComponent } from './views/manager/settlement/settlement.component';
import { PaymentComponent } from './views/manager/utility/payment/payment.component';
import { TopupComponent } from './views/manager/topup/topup.component';
import { TopUpsComponent } from './views/manager/utility/top-ups/top-ups.component';
import { LogsComponent } from './views/manager/utility/logs/logs.component';

import { InvoicesComponent } from './views/manager/invoices/invoices.component';
import { CreateInvoiceComponent } from './views/manager/invoices/create-invoice/create-invoice.component';


const routes: Routes = [
{ path: 'login', component: LoginComponent, data: { title: 'Login' } },
  { path: 'forgot_password', component: ForgetPasswordComponent },
  { path: 'lock', component: LockComponent, data: { title: 'Lock' } },
  {
    path: '', component: NavComponent, canActivate: [AuthGuard],
    children: [
      { path: '', component: DashboardComponent },
      { path: 'dashboard', component: DashboardComponent },
      { path: 'alerts', component: AlertsComponent, data: { title: 'Alerts' }, canActivate: [AccountGuard] },
      // { path: 'payments', component: PaymentsComponent, data: { title: 'payment' }, canActivate: [AccountGuard] },
      { path: 'payments', canActivate: [AccountGuard], children: [ 
          { path: '', component: PaymentsComponent, canActivate: [AccountGuard] },
          { path: 'create', component: CreatePaymentComponent, canActivate: [AccountGuard] },
          { path: 'bulk-pay', component: CreatePaymentComponent, canActivate: [AccountGuard] },
        ] 
      },
      { path: 'bulk-pay', component: CreatePaymentComponent, canActivate: [AccountGuard] },
      { path: 'topup', component: TopupComponent, data: { title: 'topup' }, canActivate: [AccountGuard] },
      { path: 'settlements', component: SettlementComponent, data: { title: 'settlement' }, canActivate: [AccountGuard] },
      { path: 'items',
        children: [
          { path: '', component: ItemComponent },
          { path: 'add', component: AddItemComponent, canDeactivate: [ExitpageGuard] },
          { path: 'edit/:id', component: AddItemComponent, canActivate: [AccountGuard], canDeactivate: [ExitpageGuard] },
          { path: 'details/:id', component: ItemDetailComponent },
          { path: 'logs', component: StocksComponent }
        ]
      },
      { path: 'invoice',
        children: [
          { path: '', component: InvoicesComponent },
          { path: 'create', component: CreateInvoiceComponent, canDeactivate: [ExitpageGuard] },
          { path: 'edit/:id', component: CreateInvoiceComponent, canActivate: [AccountGuard], canDeactivate: [ExitpageGuard] },
          // { path: 'details/:id', component: ItemDetailComponent },
          // { path: 'logs', component: StocksComponent }
        ]
      },
      { path: 'packages',
        children: [
          { path: '', component: PackagesComponent, canActivate: [AccountGuard] },
          { path: 'add', component: AddItemComponent, canDeactivate: [ExitpageGuard] },
          { path: 'edit/:id', component: AddItemComponent, canActivate: [AccountGuard], canDeactivate: [ExitpageGuard] },
          { path: 'details/:id', component: ItemDetailComponent }
        ]
      },
      { path: 'orders', canActivate: [AccountGuard],
        children: [
          { path: '', component: OrdersComponent },
          { path: 'all', component: PurchasesComponent },
        ]
      },
      { path: 'customers', canActivate: [AccountGuard],
        children: [
          { path: '', component: CustomersComponent },
          { path: ':code', component: ViewCustomerComponent },
        ]
      },
      { path: 'manager', canActivate: [AccountGuard, SupportGuard],
        children: [
          { path: 'subcribers', component: SubcribersComponent, canActivate: [SupportGuard] },
          { path: 'topups', component: TopUpsComponent, canActivate: [SupportGuard] },
          { path: 'payments', canActivate: [SupportGuard], children: [ 
            { path: '', component: PaymentComponent, canActivate: [SupportGuard] },
            { path: 'create', component: CreatePaymentComponent, canActivate: [SupportGuard] },
            { path: 'bulk-pay', component: CreatePaymentComponent, canActivate: [SupportGuard] },
          ] },
          { path: 'payments/logs', component: LogsComponent, canActivate: [SupportGuard] },
          { path: 'utility', component: UtilityComponent, canActivate: [SupportGuard] },
          { path: 'settlement', component: SettlementComponent, canActivate: [SupportGuard] },
        ]
      },
      { path: 'setup', canActivate: [AccountGuard, SupportGuard],
        children: [
          { path: '', component: SetupSmsComponent, canActivate: [AdminGuard] },
          { path: 'merchants', component: SetupCompanyComponent, canActivate: [SupportGuard] },
          { path: 'branch', component: SetupBranchComponent, canActivate: [BranchGuard] },
          { path: 'sequence', component: SetupSequenceComponent, canActivate: [AdminGuard] },
          { path: 'licence', component: SetupLicenceComponent, canActivate: [AdminGuard] },
          { path: 'charges', component: SetupChargeComponent, canActivate: [SupportGuard] },
          { path: 'session', component: SetupSessionComponent, canActivate: [BranchGuard] },
          { path: 'smslog', component: SetupSmslogsComponent, canActivate: [SupportGuard] },
          { path: 'users', component: SetupUserComponent, canActivate: [SupportGuard] },
          { path: 'alerts', component: AlertsComponent, data: { title: 'Alerts' }, canActivate: [SupportGuard] },
          { path: 'sms', component: SetupSmsComponent, data: { title: 'Sms' }, canActivate: [AdminGuard]  },
          { path: 'location', component: SetupLocationComponent, canActivate: [SupportGuard, AdminGuard] },
        ]
      },
      { path: '404', component: NotfoundComponent, data: { title: 'Error Page' } },
      { path: '**', redirectTo: '404' }
    ]
  },
  { path: '**', redirectTo: '404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
