import { Injectable } from '@angular/core';
import { Router, Routes, RouterStateSnapshot } from '@angular/router';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpUserEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ToastrService } from 'ngx-toastr';
import { ErrorsService } from '../services/errors.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    state: RouterStateSnapshot; url: any; cur: any;
    constructor(private router: Router, public toastr: ToastrService, private jwtHelper: JwtHelperService, private error: ErrorsService) {}
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.cur = req.url;
        this.url = this.cur.split('/').slice(-2)[0]; // console.log(this.url);
        if (this.url === 'upload') {
            req = req.clone({
                setHeaders: {
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ` + this.jwtHelper.tokenGetter()
                }
            });
        } else {
            req = req.clone({
                setHeaders: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ` + this.jwtHelper.tokenGetter()
                }
            });
        }
        this.error.request(req);
        // return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
        //     if (event instanceof HttpResponse) {
        //         // do stuff with response if you want
        //         if (event.body && event.ok) {
        //             // this.notify.Notify(event.body);
        //             if(event.body.access_token) {
        //                 localStorage.setItem('Paynow', JSON.stringify(event.body.access_token));
        //             }
        //         }
        //     }
        // }, (err: any) => {
        //     // this.error.log(err); // console.log(err);
        //     if (err instanceof HttpErrorResponse) {
        //         if (err.status === 401) {
        //             this.toastr.error('Login Session Expired Please Login Again', 'UnAuthorize');
        //             localStorage.removeItem('Paynow');
        //             location.reload(true);
        //         }
        //     }
        //     console.log(err.error);
        //     this.error.log(err.error);
        //     const msg = err.error.message || err.message;
        //     this.toastr.error(msg);
        //     return throwError(error);
        // }));
        return next.handle(req).pipe(catchError(err => {
            if (err.status === 401) {
                // auto logout if 401 response returned from api
                this.toastr.error('Login Session Expired Please Login Again', 'UnAuthorize');
                localStorage.removeItem('Paynow');
                location.reload(true);
                // this.router.navigate(['/login'], { queryParams: { returnUrl: this.state.url } });
            }
            console.log(err);
            this.error.log(err.error);
            const error = err.error.message || err.error.status_message || err.statusText;
            this.toastr.error(error);
            return throwError(error);
        }));
    }
}

