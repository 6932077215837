import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { SigninService } from 'src/app/services/signin.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-log',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  log: any; isLoginError: boolean; res: any; returnUrl: string;
  constructor(private _api: SigninService, private router: Router, private route: ActivatedRoute, private _msg: ToastrService) { }

  ngOnInit() {
    this.resetLogin();

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    // console.log(this.returnUrl);
  }

  get() {
    const token = this.route.snapshot.queryParams['token'] || '';
    const date = this.route.snapshot.queryParams['date'] || '';
    this.res = this.route.snapshot.queryParams['message'] || '';
    if (token) {
      localStorage.setItem('Paynow', JSON.stringify(token));
      localStorage.setItem('Date', JSON.stringify(date));
      this._msg.success('You have been Successfully logedin to Paynow Africa Portal', 'Login');
      window.location.href = 'dashboard';
      // this.router.navigate([this.returnUrl]); location.reload(true);
    } else if (this.res) {
      this._msg.error(this.res, 'Status Message');
    }
  }

  login() {
    this._api.loginUser(this.log).subscribe((data: any) => {
      if  (data && data.access_token) {
        localStorage.setItem('Paynow', JSON.stringify(data.access_token));
        localStorage.setItem('Date', JSON.stringify(data.date));
        this._msg.success('You have been Successfully logedin to Paynow Africa Portal', 'Login');
        window.location.href = this.returnUrl;
      // this.router.navigate([this.returnUrl]); location.reload(true);
      }
    }, (err: HttpErrorResponse) => {
      // this.isLoginError = true; this.res = 'Incorrect Username or Password';
      this._msg.error('Unable to login : ' + err, 'Login UnSuccessfull');
    });
 }

  resetLogin(form?: NgForm) {
    if (form != null) { form.reset(); }
    this.log = { username: '', password: '' };
  }

}
