import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ManagerService } from 'src/app/services/manager.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ExportService } from 'src/app/services/export.service';

@Component({
  selector: 'app-stocks',
  templateUrl: './stocks.component.html',
  styleUrls: ['./stocks.component.sass']
})
export class StocksComponent implements OnInit {
  stocks: any[] = []; filtered: any[] = []; st: any; id = 0; exp: any;
  // tslint:disable-next-line:variable-name
  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }
  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.filtered = this.serachResult(value);
  }
  constructor(private api: ManagerService, private use: NavComponent, private ext: ExportService) { }

  ngOnInit() {
    this.getAllStock();
  }

  getAllStock() {
    this.api.getStocks().subscribe((data: any) => {
      this.stocks = data; this.filtered = data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Stock List');
    });
  }

  serachResult(searchString: string) {
    // tslint:disable-next-line:max-line-length
    return this.stocks.filter(a => a.name.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.code.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.mobile.indexOf(searchString) !== -1);
  }

  read(event) {
    this.ext.readExcel(event.target.files[0]);
  }

  remove(index) {
    this.ext.import.splice(index, 1);
  }

  export() {
    if (this.filtered.length > 0) {
      const data = this.format();
      this.ext.exportAsExcelFile(data, 'stocks');
      this.use.showMsg('success', 'Stocks Exported', 'Check Downloaded Excel File For Details');
    } else {
      this.use.showMsg('error', 'Stocks Export Fail', 'There is no Stock Data to Export');
    }
  }

  format() {
    this.id = 1; this.exp = [];
    this.filtered.forEach((e: any) => {
      const ed = {
        SN: this.id, CODE: e.code, NAME: e.name, QUANTITY: e.quantity, PRICE: e.price,
        AMOUNT: e.amount, CATEGORY: e.category, DISCOUNT: e.discount,
        DESCRIPTION: e.description, STATUS: e.status, CREATEDDATE: e.date, IMAGE: e.image
      };
      this.exp.push(ed); this.id++;
    });
    return this.exp;
  }

}
